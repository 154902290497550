<!--  -->
<template>
  <div>
    <img src="./logo.jpg" alt="">
  </div>
</template>
<script >
export default {
    data() {
      return{
      }
    },
    methods:{
    },
    mounted() {
    },
  };
</script>
<style lang='less' scoped>
 img{
    width: 100%;
    // height: 100vh;
 }
</style>